import React from 'react';

const LoadingOverlay: React.FunctionComponent<{
    loading: boolean
}> = (props) => {
    return (
        <div
            style={{
                display: props.loading ? 'flex' : 'none',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'fixed',
                zIndex: 1060,
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
                overflow: 'auto',
                backgroundColor: 'rgba(0,0,0,0.4)',
            }}
        >
            <div className="text-center user-select-none">
                <div className="spinner-border text-danger" />
                <h3 className="text-center text-white my-auto">Loading...</h3>
            </div>
        </div>
    );
};

export default LoadingOverlay;
