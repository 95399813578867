import React, { useEffect } from 'react';
import App, { AppContext, AppProps } from 'next/app';
import type { NextComponentType, NextPageContext } from 'next';
import Router from 'next/router';
import NProgress from 'nprogress';
import { config } from '@fortawesome/fontawesome-svg-core';
import { UserManagerFactory } from '../src/js/services/UserManagerFactory';
import { UserManager } from 'oidc-client';
import LoadingOverlay from '../src/js/components/LoadingOverlay';

import '../src/css/index.scss';
config.autoAddCss = false;

type NextComponentWithLayout = NextComponentType<NextPageContext, unknown, unknown> & {
    layout?: React.ComponentType
}

function CustomApp({ Component, pageProps }: AppProps): JSX.Element {
    const [isLoading, setLoading] = React.useState(true);

    async function tryLogin(userManager: UserManager, silent = true) {
        if (silent === false) {
            setLoading(true);
        }

        try {
            await userManager.signinSilent();
        }
        catch (error) {
            console.log('Signin silent error', error);
            await userManager.signoutRedirect();
        }

        setLoading(false);
    }

    useEffect(() => {
        import('bootstrap');

        (async () => {
            const userManager = UserManagerFactory();
            const user = await userManager.getUser();

            if (user !== null && user.expired) {
                setLoading(true);
            }
            else {
                setLoading(false);
            }
            
            userManager.events.addAccessTokenExpiring(() => {
                tryLogin(userManager);
            });
            userManager.events.addAccessTokenExpired(() => {
                tryLogin(userManager, false);
            });
        })();
    }, []);

    const Layout = (Component as NextComponentWithLayout).layout ?? React.Fragment;
    return (
        <React.Fragment>
            <LoadingOverlay loading={isLoading}/>
            <Layout>
                {isLoading === true
                    ? <React.Fragment />
                    : <Component {...pageProps} />
                }
            </Layout>
        </React.Fragment>
    );
}

// This disables the ability to perform Automatic Static Optimization... 
// Causing every page in the app to be server-side rendered,
// but allowing the use of runtime configuration in Docker-based Environment!
CustomApp.getInitialProps = async (appContext: AppContext) => {
    // calls page's `getInitialProps` and fills `appProps.pageProps`
    const appProps = await App.getInitialProps(appContext);
    return { ...appProps };
}

Router.events.on('routeChangeStart', NProgress.start);
Router.events.on('routeChangeComplete', NProgress.done);
Router.events.on('routeChangeError', NProgress.done);

export default CustomApp;
